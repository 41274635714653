<script setup lang="ts">

import {ref} from 'vue'
import {useStore} from "vuex";
import {actions, mutations} from "@/store";

const store = useStore()

const config = ref({
  identifier: store.getters.config('identifier'),
  prompt: store.getters.config('prompt'),
  database: store.getters.config('database')
})

const databases = store.getters.databases

const deleteHistroy = () => {
  if (confirm("Are you sure you want to delete your history?")) {
    store.dispatch(actions.deleteUserHistory)
  }
}
const updateSettings = () => {
  store.commit(mutations.updateConfig, config.value)
  alert('config updated!')
}


</script>

<template>
  <main class="col-12 configurations">
    <h2>Configuration</h2>
    <div id="conversation-container">
      <form action="#" @submit.prevent="updateSettings">
        <div class="row g-3 mb-3 align-items-center">
          <div class="col-auto col-xl-1 col-md-2 col-3">
            <label for="identifier" class="col-form-label">User Identifier</label>
          </div>
          <div class="col">
            <input type="text"
                   name="identifier"
                   id="identifier"
                   class="form-control"
                   placeholder="default"
                   v-model="config.identifier"/>
          </div>
        </div>

        <div class="row g-3 mb-3 align-items-center">
          <div class="col-auto col-xl-1 col-md-2 col-3">
            <label for="prompt" class="col-form-label">Prompt</label>
          </div>
          <div class="col">
            <textarea name="prompt"
                      id="prompt"
                      class="form-control"
                      placeholder="You are a data scientist."
                      v-model="config.prompt"></textarea>
          </div>
        </div>

        <div class="row g-3 mb-3 align-items-center">
          <div class="col-auto col-xl-1 col-md-2 col-3">
            <label for="database" class="col-form-label">Database</label>
          </div>
          <div class="col">
            <select name="database"
                    id="database"
                    class="form-control" v-model="config.database">
              <option v-for="db in databases"
                      :key="db.name"
                      :value="db.name">
                {{ db.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group d-flex justify-content-between">
          <button class="btn btn-danger" type="button" @click.prevent="deleteHistroy"> Remove User History</button>
          <input class="btn btn-primary" type="submit" value="Update"/>
        </div>

      </form>
    </div>
  </main>
</template>
