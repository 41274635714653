import store from "../store";
import {Database, DatabaseTable, Example} from "../types";
import {get} from "@/services/sessionStorage";
import {config} from "@/config";

export const host = config.api_host

export class UnAuthenticatedError extends Error {
    constructor(message: string) {
        super(message)
        this.name = "UnAuthenticatedError"
    }
}

export async function sendMessage(query: string) {

    const url = `${host}/api/messages/streaming?`
    let res = fetch(url + new URLSearchParams({query}), {
        method: "GET",
        headers: {
            "x-identifier": store.getters.config('identifier'),
            "x-prompt": store.getters.config('prompt'),
            "x-database": store.getters.config('database'),
        }
    });

    return {
        url,
        res
    }
}

export async function getMessages() {
    const url = `${host}/api/messages`
    return fetch(url, {
        credentials: "include",
        headers: {
            "authorization": await get('token')?.token,
            "x-identifier": store.getters.config('identifier'),
        }
    })
        .then(async (res) => {
            if (res.status === 401 && "Token expired" === await res.text()) {

            } else {
                return res.json()
            }

        })
        .catch(e => {
            console.log("cannot get messages", e)
        })
}

export async function getQueries() {
    const url = `${host}/api/examples`
    let res = await fetch(url, {
        headers: {
            "authorization": get('token')?.token,
            "x-database": store.getters.config('database'),
        }
    });
    return await res.json();
}

export async function updateQueries(example: Example) {
    const url = `${host}/api/examples/${example.id}`
    let res = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(example), headers: {
            "Content-Type": "application/json",
            "authorization": get('token')?.token,
            "x-database": store.getters.config('database'),
        }
    });
    return await res.json();
}

export async function getDatabases() {
    const url = `${host}/api/databases`
    let res = await fetch(url, {
        headers: {
            "authorization": get('token')?.token,
        }
    });
    return await res.json();
}

export async function postDatabase(data: Omit<Database, "id">) {
    const url = `${host}/api/databases`
    let res = await fetch(url, {
        method: "POST", body: JSON.stringify(data), headers: {
            "authorization": get('token')?.token,
            "Content-Type": "application/json",
        }
    });
    return await res.json();

}

export async function getDatabaseTables(database: string) {
    const url = `${host}/api/databases/${database}/tables`
    let res = await fetch(url, {
        headers: {
            "authorization": get('token')?.token,
        }
    });
    return await res.json();
}

export async function updateDatabaseTable(database: string, table: DatabaseTable) {
    const url = `${host}/api/databases/${database}/tables/${table.id}`
    let res = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(table),
        headers: {
            "authorization": get('token')?.token,
            "Content-Type": "application/json",
        }
    });
    return await res.json();
}

export async function getDatabaseTableColumns(database: string, tableId: number) {
    const url = `${host}/api/databases/${database}/tables/${tableId}/columns`
    let res = await fetch(url, {
        headers: {
            "authorization": get('token')?.token,
        }
    });
    return await res.json();
}

export async function getDatabaseTableColumn(database: string, tableId: number, columnId: string) {
    const url = `${host}/api/databases/${database}/tables/${tableId}/columns/${columnId}`
    let res = await fetch(url, {
        headers: {
            "authorization": get('token')?.token,
        }
    });
    return await res.json();
}

export async function updateDatabaseTableColumn(database: string, tableId: number, columnId: string, data: any) {
    const url = `${host}/api/databases/${database}/tables/${tableId}/columns/${columnId}`
    let res = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "authorization": get('token')?.token,
            "Content-Type": "application/json",
        }
    });
    return await res.json();
}

export async function deleteUserHistory(identifier: string,) {
    const url = `${host}/api/messages/${identifier}`
    return fetch(url, {
        credentials: "include",
        method: "DELETE",
        headers: {
            "authorization": await get('token')?.token,
            "x-identifier": store.getters.config('identifier'),
        }
    })
        .then(async (res) => {
            if (res.status === 401 && "Token expired" === await res.text()) {

            } else {
                return res.json()
            }

        })
        .catch(e => {
            console.log("cannot get messages", e)
        })
}