<script setup lang="ts">
import {defineProps, nextTick, onMounted, ref, watch} from 'vue'
import *  as echarts from 'echarts'

type Props = {
  chartOptions: any
}

const props = defineProps<Props>()

const chartContainer = ref(null)

const renderChart = () => {
  const el = chartContainer.value
  if (el) {
    console.log('rendering chart', props.chartOptions)
    try {
      const myChart = echarts.init(chartContainer.value);
      console.log('myChart option', props.chartOptions)

      const options = props.chartOptions.chart || props.chartOptions

      myChart.setOption(options)
    } catch (e) {
      console.error('renderChart component: ', e)
    }
  }
}

onMounted(() => {
  nextTick(() => {
    renderChart()
  })
})


</script>

<template>
  <div class="card mb-3 assistant-message" ref="chartContainer" style="width: 100%;height:400px;"></div>
</template>

<style lang="scss">
</style>