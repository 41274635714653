<template>
  <Header/>
  <div class="container-fluid">
    <div class="row p-3">
      <router-view/>
    </div>
  </div>
</template>

<style lang="scss">


#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script setup lang="ts">
import Header from './components/Header.vue';
import {useStore} from "vuex";
import {actions, mutations} from "@/store";

const store = useStore()

store.dispatch(actions.getDatabases)
store.commit(mutations.initConfig)

</script>