<script setup lang="ts">

import {computed, defineProps, defineEmits} from 'vue'
import {Message} from "@/types";

type Props = {
  message: Message
}

const emit = defineEmits(['showThumbnail'])


const images = computed(() => {
  if (Array.isArray(props.message.content)) {
    return props.message.content.filter((c) => c.type === 'image_url')
  }
  return []
})
const content = computed(() => {
  //check props.message.content is a json string
  if (Array.isArray(props.message.content)) {
    return props.message.content.find((c) => c.type === 'text')?.text
  }

  return props.message.content
})

const props = defineProps<Props>()

const clickThumbnail = (image) => {
  console.log('clickThumbnail', image)
  emit('showThumbnail', image)
}

</script>

<template>
  <div class="card border-0 mb-3 user-message">
    <div class="row d-flex">
      <div style="width: 80px" class="d-flex justify-content-center align-content-center mt-2 align-self-center">
        <img src="./../assets/user.png" width="40" height="40" class="mb-3">
      </div>

      <div class="col align-self-center">
        <div class="text-start images" v-if="images.length">
          <div v-for="(image, index) in images" :key="index" style="display: inline-block;width: 100px; height:auto">
            <img :src="image.imageUrl.url" alt="user image" class="img-fluid" @click="clickThumbnail(image)"/>
          </div>
        </div>
        <div class="card-body text-start border-bottom pb-4">
          {{ content }}
        </div>
      </div>
    </div>
  </div>
</template>