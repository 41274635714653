import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ConversationView from '@/views/ConversationView.vue'
import ConfigView from "@/views/ConfigView.vue";
import ExamplesView from "@/views/ExamplesView.vue";
import DatabaseView from "@/views/DatabaseView.vue";
import DatabaseTableView from "@/views/DatabaseTableView.vue";
import DatabaseTableColumnView from "@/views/DatabaseTableColumnView.vue";
import AddDatabaseView from "@/views/AddDatabaseView.vue";
import EditDatabaseTableColumnView from "@/views/EditDatabaseTableColumnView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        redirect: '/conversations'
    },
    {
        path: '/conversations',
        name: 'conversations',
        component: ConversationView
    },
    {
        path: '/configs',
        name: 'configs',
        component: ConfigView
    },
    {
        path: '/examples',
        name: 'examples',
        component: ExamplesView
    },
    {
        path: '/databases',
        name: 'databases',
        component: DatabaseView
    },
    {
        path: '/databases/create',
        name: 'new database',
        component: AddDatabaseView
    },
    {
        path: '/databases/:name/tables',
        name: 'database tables',
        component: DatabaseTableView
    },
    {
        path: '/databases/:database/tables/:tableId/columns',
        name: 'database table columns',
        component: DatabaseTableColumnView
    },
    {
        path: '/databases/:database/tables/:tableId/columns/:columnId/edit',
        name: 'edit database table column',
        component: EditDatabaseTableColumnView
    },
    {
        path: '/register',
        name: 'register',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "register" */ '../views/Login.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
