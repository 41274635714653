<template>
  <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 mt-4">
    <div class="col-md-4 d-flex align-items-center">
      <a href="/" class="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
        <img src="./../assets/tc-logo-color.png" width="150" height="auto" alt="">
      </a>
    </div>

    <span class="mb-3 mb-md-0 text-body-secondary justify-content-end">© Turn Cloud.</span>
  </footer>
</template>

<style scoped lang="scss">
footer {
  margin-bottom: 70px;

  span {
    text-transform: uppercase;
  }
}
</style>
<script setup lang="ts">
</script>