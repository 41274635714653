import {marked} from "marked";
import DOMPurify from "dompurify";

export function convertMdToHTML(md: string): string {
    if (!md) {
        return ""
    }

    return DOMPurify.sanitize(marked.parse(md) as string).trim()
}

export function extractEventAndValue(txt: string) {
    return txt.split("event:")
        .map(s => s.split('\ndata: ')
            .filter(c => c.length > 0))
        .filter((d) => d.length)
        .map(d => {
            return {
                event: d[0].slice(1),
                value: d[1].slice(0, -2)
            }
        }) || []
}

export function scrollToBottom() {
    const scrollHeight = document.body.scrollHeight;
    window.scrollTo(0, scrollHeight);
}
