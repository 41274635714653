<script setup lang="ts">

import {onMounted, ref} from 'vue'
import {useStore} from "vuex";
import {actions} from "@/store";
import UserMessage from "@/components/UserMessage.vue";
import AssistantMessage from "@/components/AssistantMessage.vue";
import UserInput from "@/components/UserInput.vue";
import {UnAuthenticatedError} from "@/services/client";
import {useRouter} from "vue-router";
import {get} from "@/services/sessionStorage";
import Footer from "@/components/Footer.vue";

const store = useStore()
const imageModal = ref<HTMLElement>()
const router = useRouter()

if (get('token')) {
  router.push('/login')
}


onMounted(() => {
  store.dispatch(actions.getMessages)
})
const showImage = () => {
  console.log('showImage')
  document.querySelector('#overlay')?.classList.add('show')
}

const closeModal = () => {
  console.log('closeModal')
  document.querySelector('#overlay')?.classList.remove('show')
}

</script>

<template>
  <main class="col-12">
    <h2 class="text-start">T-Coeus</h2>
    <div id="conversation-container">
      <div v-for="message in store.state.messages" :key="message.id">
        <UserMessage v-if="message.role === 'user'" :message="message" @show-thumbnail="showImage"/>
        <AssistantMessage v-else :message="message"/>
      </div>
    </div>

    <UserInput/>


  </main>

  <Footer />
</template>

