<template>
  <nav class="navbar navbar-expand-lg navbar-dark sticky-top flex-md-nowrap shadow">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"><img src="./../assets/tc-logo-bw.png" width="150" height="auto"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'conversations'}">Conversations</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'configs'}">Settings</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'examples'}">Examples</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'databases'}">Databases</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">
@import '@/assets/scss/main.scss';

nav {
  background-color: $tc_primary;
  // set smooth transition
  transition: top 0.3s;
}

</style>
<script setup lang="ts">


// when user scrolls down, hide the navbar
const hideNavbar = () => {
  const navbar: HTMLElement = document.querySelector('.navbar')!
  if (navbar) {
    navbar.style.top = '-100px'
  }
}

/// when user scrolls up, show the navbar
const showNavbar = () => {
  const navbar: HTMLElement = document.querySelector('.navbar')!
  if (navbar) {
    navbar.style.top = '0'
  }
}

// get the current scroll position
const getScrollPosition = () => {
  return window.pageYOffset || document.documentElement.scrollTop
}

// get the last scroll position
let lastScrollPosition = getScrollPosition()

// listen for scroll event
window.addEventListener('scroll', () => {
  const currentScrollPosition = getScrollPosition()

  // if the current scroll position is greater than the last scroll position, hide the navbar
  if (currentScrollPosition > lastScrollPosition) {
    hideNavbar()
  } else {
    showNavbar()
  }

  // set the last scroll position to the current scroll position
  lastScrollPosition = currentScrollPosition
})



</script>