<template>
  <div class="register">
    <form action="" class="form" @submit.prevent="submit">
      <div class="form-group">
        <label for="name">Name</label>
        <input class="form-control" type="text" id="name" v-model="form.name" required/>
      </div>
      <div class="form-group">
        <label for="username">Username</label>
        <input class="form-control" type="text" id="username" v-model="form.username" required/>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input class="form-control" type="password" id="password" v-model="form.password" required/>
      </div>
      <div class="form-group">
        <label for="password_confirmation">Password Confirmation</label>
        <input class="form-control" type="password" id="password_confirmation" v-model="form.password_confirmation"
               required/>
      </div>

      <div class="form-group">
        <button type="submit" class="btn btn-primary btn-success" :disabled="state.isSubmitting">Register</button>
      </div>

    </form>
  </div>
</template>

<script lang="ts" setup>
import {ref} from 'vue';

const state = ref({
  isSubmitting: false
})
const form = ref({
  name: '',
  username: '',
  password: '',
  password_confirmation: ''
})


const submit = () => {
  if (form.value.password !== form.value.password_confirmation) {
    alert('Password and password confirmation do not match')
    return
  }

  state.value.isSubmitting = true

  fetch('http://localhost:3100/api/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(form.value)
  })
      .finally(() => {
        state.value.isSubmitting = false
      })
}

</script>


<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>