<script setup lang="ts">

import {nextTick, ref} from "vue";
import {mutations} from "@/store";
import {host} from "@/services/client";
import {useStore} from "vuex";
import {useDropZone} from '@vueuse/core'
import DocumentPreview from "@/components/UserInput/DocumentPreview.vue";
import {extractEventAndValue, scrollToBottom} from "@/utils/string";


const files = ref<File[]>([])
const file_paths = ref<string[]>([])

const store = useStore()
const query = ref('')
const is_loading = ref(false)
const dropZoneRef = ref<HTMLDivElement>()
const userInput = ref<HTMLElement>()
const {isOverDropZone} = useDropZone(dropZoneRef, {
  onDrop,
  // specify the types of data to be received.
  dataTypes: ['image/png']
})

function onDrop(fs: File[] | null) {
  fs?.forEach(f => files.value.push(f))
}

const saveFilePath = (file_path: string) => {
  file_paths.value.push(file_path)
}

const removeFile = (payload: {
  name: string,
  path?: string | null
}) => {
  files.value = files.value.filter(f => f.name !== payload.name)
  if (payload.path) {
    file_paths.value = file_paths.value.filter(f => f !== payload.path)
  }
}

const focusInput = () => {
  userInput.value?.focus()
}

function ask() {
  if (query.value.length === 0) {
    return
  }
  const message = query.value
  store.commit(mutations.addTempUserMessage, message)
  const url = `${host}/api/messages/streaming?`
  const params = {
    query: query.value,
    files: file_paths.value.join(',')
  }
  const headers = {
    "authorization": `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1IiwidGltZSI6IlR1ZSBKdWwgMDIgMjAyNCAxNzozNDo0NSBHTVQrMDgwMCAoSG9uZyBLb25nIFN0YW5kYXJkIFRpbWUpIiwiZXhwIjoxNzE5OTE0Njg1LCJpYXQiOjE3MTk5MTI4ODV9.66CaaO11VegYJBj1iTlc-Q2ZgvCN2pQ6iFrbi8R7qE4`,
    "x-identifier": store.getters.config('identifier'),
    "x-prompt": encodeURI(store.getters.config('prompt')),
    "x-database": store.getters.config('database'),
  }

  console.log('headers', headers)

  is_loading.value = true

  fetch(url + new URLSearchParams(params), {
    method: "GET",
    headers
  })
      .then(async (response) => {
        // Get the readable stream from the response body
        const stream = response.body;

        if (!stream) {
          return
        }

        store.commit(mutations.addMessage, {'role': 'assistant', 'content': ''})
        // Get the reader from the stream
        const reader = stream.pipeThrough(new TextDecoderStream()).getReader();

        while (true) {
          const {value, done} = await reader.read()

          if (done) {
            // Log a message
            console.log('Stream finished');
            // Return from the function
            query.value = ""
            is_loading.value = false

            break
          }

          extractEventAndValue(value as string)
              .map((a) => {
                if (a.event === "message") {
                  console.log('steamingMessage', a.value)
                  store.commit(mutations.updateLatestMessage, {
                    deltaMessage: a.value || "",
                    meta: {notification: null}
                  })
                } else if (a.event === "tool") {
                  const json: any = JSON.parse(a.value)
                  const toolMessage = {
                    deltaMessage: "",
                    meta: {
                      ...json.meta,
                      notification: json.message,
                    }
                  }
                  console.log("toolMessage: ", toolMessage)
                  store.commit(mutations.updateLatestMessage, toolMessage)
                }

                nextTick(() => scrollToBottom())
              })
        }
      })
}


</script>

<template>
  <div class="user-input">
    <div ref="filePreview" class="filePreview text-start">
      <DocumentPreview v-for="file in files" :key="file.name"
                       :file="file"
                       @remove-file="removeFile"
                       @upload-success="saveFilePath"/>
    </div>
    <div class="files-preview"></div>
    <form action="#" class="form bottom-0" @submit.prevent="ask">
      <div>
        <div class="border-0 p-3 input-container">
          <div class="col d-flex gap-2 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            <input type="text" name="message" id="query" ref="userInput" class="form-control" placeholder="Your message"
                   v-model="query"
                   :disabled="is_loading">
            <button
                class="sent-button btn btn-outline-secondary d-flex align-items-center justify-content-center gap-1 text-light border-0"
                type="submit"
                id="button-addon2"
                :disabled="is_loading">
              <img src="./../assets/icon-send.svg" width="15" height="15"> Send
            </button>
          </div>
        </div>

      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

//.user-input stick to the bottom
.input-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(250, 250, 250, 0.5);
  padding: 10px;
  backdrop-filter: blur(5px);
}


.dropZoneRef {
  z-index: 1;
  margin: 0 40px 40px 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: block;
  //background: rgba(0, 0, 0, 0.1);
  //background-color: yellow;
}

.sent-button {
  background-color: $tc_primary;
}

#query {
  background-color: #DCE1E5;
}

#query:focus {
  background-color: #DCE1E5;
  // input glow in gray
  border-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.05) !important;
}

#query:focus + .sent-button {
  background-color: $tc_primary_darken;
}
</style>