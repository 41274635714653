<template>
  <div class="register">
    <form action="" class="form" @submit.prevent="submit">
      <div class="form-group">
        <label for="username">Username</label>
        <input class="form-control" type="text" id="username" v-model="form.username" required/>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input class="form-control" type="password" id="password" v-model="form.password" required/>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary btn-success">Login</button>
      </div>

    </form>
  </div>
</template>

<script lang="ts" setup>
import {ref} from 'vue';
import {set} from "@/services/sessionStorage";
import {useRouter} from "vue-router";

const router = useRouter()

const form = ref({
  username: '',
  password: '',
})


const submit = () => {

  fetch('http://localhost:3100/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(form.value)
  })
      .then(res => res.json())
      .then(async (json) => {
        console.log(json)
        if (json.status === 'success') {
          console.log('token', json.token)
          await set('token', json)
          router.push('/conversations')
        } else {
          alert(json.message)
        }
      })
      .catch(error => {
        console.error(error)
      })
}

</script>


<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>