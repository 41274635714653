export function set(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value))
}

export function get(key: string) {
    const value = sessionStorage.getItem(key)
    if (value) {
        return JSON.parse(value)
    }
    return null
}