<script setup lang="ts">
import {computed, defineProps, nextTick, onMounted, ref, watch} from 'vue'
import {Message} from "@/types";
import ChartComponent from "./ChartComponent.vue";
import {convertMdToHTML} from "@/utils/string";

type Props = {
  message: Message
}

const convertToHTML = (markdown: string) => convertMdToHTML(markdown)

const convertedHTML = computed(() => {
  return convertToHTML(props.message.content)
})

const rawContent = ref('')
const htmlContent = ref('')

const props = defineProps<Props>()

const chartContainer = ref(null)

const chatOptions = ref<any[]>([])

const showChart = ref(false)

watch(props.message, async (newValue: any) => {

      console.log('Prop watcher New Value', newValue)
      if (newValue.content) {
        rawContent.value = newValue.content
        htmlContent.value = convertMdToHTML(rawContent.value)
      }

      if (newValue.meta &&
          newValue.meta.toolName === 'chart_generator' &&
          newValue.meta.result &&
          !showChart.value) {
        console.log('New Value', newValue.meta.result)
        chatOptions.value.push(newValue.meta.result)
      }
    }
)


onMounted(async () => {

  rawContent.value = props.message.content
  htmlContent.value = convertMdToHTML(rawContent.value)


  const meta = props.message.meta
  if (meta?.chat) {
    chatOptions.value.push(meta)
  } else if (Array.isArray(meta?.attachment)) {
    chatOptions.value = meta!.attachment
  }
})


</script>

<template>
  <div class="card   border-0 mb-3 assistant-message">
    <div class="row d-flex">
      <div style="width: 80px" class="d-flex justify-content-center align-content-center align-self-center mt-3">
        <img src="./../assets/bot.png" width="40" height="40" class="mb-3">
      </div>

      <div class="col align-self-center">
        <div class="chart my-3" ref="chartContainer" v-if="chatOptions && chatOptions.length">
          <ChartComponent v-for="(options, index) in chatOptions" :key="index"
                          :chart-options="options"></ChartComponent>
        </div>
        <div class="notification p-3 border-1 border-primary w-50 text-start border rounded m-3"
             v-if="message.meta?.notification"><strong>{{ message.meta?.notification }}</strong></div>
        <div class="card-body text-start border-bottom pb-4" v-html="htmlContent"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
table, th, td {
  border: 1px solid;
}

.katex-html {
  display: none;
}

.assistant-message .card-body {
  & table,
  & th,
  & td {
    border: 1px solid;
    padding: 5px
  }

  & table {
    max-width: 100%;
    overflow-x: scroll;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0;
  }
}

</style>